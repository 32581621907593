<template>
  <div>
    <b-button variant="primary" @click="openModal">
      <b-spinner v-if="isLoading" small class="mr-50" />
      <feather-icon v-else
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Add Badges</span>
    </b-button>
    <b-modal
      id="tab"
      ok-only
      ok-title="Save"
      centered
      title="Create Badges"
      v-model="isOpen"
      @ok="save"
    >
        <b-form-group
            label="Name"
            label-for="h-name"
            label-cols-md="4"
        >
          <b-form-input
            id="h-name"
            type="text"
            v-model="name"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group
            label="Key"
            label-for="h-key"
            label-cols-md="4"
        >
          <b-form-input
            id="h-key"
            type="text"
            v-model="key"
            placeholder="Key"
          />
        </b-form-group>
        <b-form-radio-group
          v-model="type"
          :options="types"
          class="demo-inline-spacing mb-1"
          value-field="value"
          text-field="text"
        />

    </b-modal>
  </div>
</template>

<script>
import service from '../../service'

export default {
  components: {
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      type: 1,
      name: null,
      key: null,
      types: [
        {
          text: 'Chuyên cần ',
          value: 1
        },
        {
          text: 'Thành tích',
          value: 2
        },
        {
          text: 'Tương tác',
          value: 3
        }
      ]
    }
  },
  methods: {
    openModal() {
      this.name = null
      this.key = null
      this.isOpen = true
    },
    async save() {
      this.isLoading = true
      let res = await service.create({ name: this.name , key: this.key, type: this.type })
      this.isLoading = false
      if(res.data.type == 'DATA') {
       this.$store.dispatch('pushSuccessNotify', { text: 'Successfully created badges!' })
       this.$router.push({ name: 'badges-detail', params: { id: res.data.data.new_document._id } })
      }
    }
  },
}
</script>
