<template>
    <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Badges" class="mb-3" />
      </b-col>
      <b-col md="2 text-right">
        <badges-create-form @refresh_data="getList" />
      </b-col>
    </b-row>
    
    <badges-card v-for="(item, index) in badges" :key="item._id || index" :item="item" :ranks="ranks" />
  </div>
</template>

<script>
import BadgesCreateForm from './_components/BadgesCreateForm.vue'
import BadgesCard from './_components/BadgesCard.vue'
import service from '../service'
import defaultRank from '../defaultRank'

export default {
  components: {
    BadgesCreateForm,
    BadgesCard,
  },
  data() {
    return {
      badges: [],
      ranks: defaultRank
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      let res = await service.getList()
      if(res && res.data && res.data.data && res.data.data.list){
        this.badges = res.data.data.list
      }
      
    }
  }
}
</script>
